.faq-page-container {
  padding-top: 140px;
  padding-bottom: 40px;
  .faq-header-title {
    display: flex;
    justify-content: flex-end;

    h1 {
      font-size: var(--main-font-size);
      margin-bottom: 100px;
      max-width: 620px;
      text-align: right;
      font-family: "OutfitBold";
      @media screen and (max-width: 768px) {
        max-width: 100%;
        font-size: 64px;
        text-align: left;
      }
    }
  }
}
.faq-body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  gap: 10px;
}
.faq-body {
  width: 40%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

header {
  background-color: black;
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .App-header {
    color: white;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .logo-container {
    img {
      width: 100%;
      @media screen and (max-width: 768px) {
        height: 45px;
      }
    }
  }

  .header-menu {
    margin-right: auto;
    display: flex;
    align-items: center;

    a {
      display: block;
      padding: 6px 30px 6px 10px;
    }
  }

  .header-menu a {
    // padding: 0 51px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    font-family: "Outfit";
  }

  .header-icons {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  //-----------------Mobile Menu--------------------
  .mobile-handler {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
    }
  }

  .menu-trigger,
  .menu-trigger-close {
    transition: all 0.4s;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
  }

  .menu-trigger {
    background-color: white;

    &::before {
      transform-origin: 33% 100%;
      top: -10px;
      z-index: 10;
      background-color: white;
      height: 2px;
      left: 0;
      width: 75%;
      content: "";
      display: block;
      position: absolute;
      transition: all 0.4s;
    }

    &::after {
      content: "";
      transform-origin: 33% 0;
      top: 10px;
      transition: all 0.4s;
      background-color: white;
      display: block;
      position: absolute;
      height: 2px;
      left: 0;
      width: 75%;
    }
  }

  .menu-trigger-close {
    background-color: transparent;

    &::before {
      content: "";
      transition: all 0.4s;
      transform: translateY(6px) translateX(0px) rotate(45deg);
      background-color: white;
      width: 30px;
      top: -10px;
      z-index: 10;
      height: 2px;
      display: block;
      position: absolute;
    }

    &::after {
      content: "";
      transition: all 0.4s;
      transform: translateY(-3px) translateX(0px) rotate(-45deg);
      background-color: white;
      width: 30px;
      height: 2px;
      display: block;
      position: absolute;
    }
  }

  .mobile-nav-bar {
    width: 100%;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      position: absolute;
      list-style: none;
      top: 90px;
      left: 0px;
      background-color: #00000080;
      z-index: 20;

      .header-menu {
        flex-direction: column;
        flex-shrink: 0;
        width: 200px;
        margin-right: 0;
      }

      a {
        text-decoration: none;
        margin: 10px;
        font-size: 20px;
        text-transform: uppercase;
        color: white;
        padding: 10px;
      }
      .header-icons {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 20px;
      }
    }
  }
  //Web3 modal
  .wallet-select-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    .wallet-connect-modal-overlay {
      background-color: #00000080;
      width: 100%;
      height: 100%;
    }
    .wallet-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // width: 50%;
      background-color: rgb(0, 0, 0);

      width: 80%;
      max-width: 600px;

      display: flex;
      flex-direction: column;
      padding: 70px;

      @media screen and (max-width: 576px) {
        padding: 24px;
      }

      h1 {
        font-size: 32px;
        font-weight: 800;
        margin: 10px;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 48px;
      }

      .buttons {
        display: flex;
        gap: 24px;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-width: 576px) {
          flex-direction: column;
        }

        .wallet-buttons {
          cursor: pointer;
          img {
            height: 54px;
          }

          p {
            color: white;
          }
        }
      }
    }
  }
  //---------Button style------------
  .common-button {
    border-radius: 24px;
    border: none;
    background-color: white;
    padding: 7px 22px;
    color: #2c2f3e;
    font-family: "OutfitBold";
    font-size: 24px;
    line-height: 24px;
    z-index: 20;
    position: relative;
    margin-left: 20px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .disabled-button {
    background-color: #888694;
    border: 1px solid #888694;
    color: #2c2f3e;
  }
}

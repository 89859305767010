.chickencard-container {
  background-color: black;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .ring1 {
    position: absolute;
    right: 50px;
    top: -70px;
    z-index: 100000 !important  ;

    width: 220px;
    height: 220px;
    border-radius: 50%; /*1*/
    border: 40px solid transparent; /*2*/
    background: linear-gradient(to right, black, var(--theme-color)) border-box; /*3*/
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
//    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    opacity: 0.5;
    transform: rotateZ(-45deg);

    @media screen and (max-width: 768px) {
      width: 120px;
      height: 120px;
      border-width: 26px;
      top: -40px;
    }
  }

  .ring2 {
    position: absolute;
    right: 240px;
    top: 90px;
    z-index: 100000 !important  ;

    width: 120px;
    height: 120px;
    border-radius: 50%; /*1*/
    border: 24px solid transparent; /*2*/
    background: linear-gradient(to right, black, var(--theme-color)) border-box; /*3*/
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    opacity: 0.5;
    transform: rotateZ(135deg);

    @media screen and (max-width: 768px) {
      width: 80px;
      height: 80px;
      border-width: 18px;
      right: 160px;
      top: 60px;
    }
  }

  h1 {
    font-size: var(--main-font-size);
    font-weight: 700;
    width: 500px;
    color: white;
    font-family: "Outfit";
    text-align: left;

    padding-top: 10%;
    margin-top: 0;
    margin-bottom: 140px;

    position: relative;
    z-index: 10000000;

    @media screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      padding: 70px 0px 0px 0;
      margin-bottom: 70px;
    }
  }

  .chicken-images {
    display: flex;
    padding-left: 10%;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 50px;
      padding: 0;
    }
  }

  .chicken {
    width: 27%;
    padding: 20px 20px 0 20px;

    background-color: white;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      padding: 20px;
    }

    p {
      font-size: 24px;
      font-weight: bold;
      margin-top: 10px;

      @media screen and (max-width: 992px) {
        font-size: 18px;
      }
      @media screen and (max-width: 768px) {
        text-align: left;
      }
    }

    img {
      display: block;
      width: 100%;
      border-radius: 14px 14px 0 0;

      @media screen and (max-width: 768px) {
        border-radius: 0;
      }
    }
  }

  .chicken:last-child {
    width: 35%;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      padding: 20px;
      margin-bottom: 70px;
    }
  }
}

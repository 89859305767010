html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --chicken-padding: 145px;
  --mobile-padding: 45px;
  --main-font-size: 72px;
  --secondary-font-size: 52px;
  --main-line-height: 80px;
  --main-button-color: #08ffb5;
  --mobile-responsive-size: 768px;
}
@media screen and (max-width: 768px) {
  body {
    --main-font-size: 54px;
    --secondary-font-size: 40px;
    --chicken-padding: 45px;
    --main-line-height: 72px;
  }
}
@media screen and (max-width: 576px) {
  body {
    --main-font-size: 42px;
    --secondary-font-size: 32px;
    --chicken-padding: 45px;
    --main-line-height: 45px;
  }
}
@font-face {
  font-family: "Mono";
  src: url(./asserts/fonts/DMMono-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "OutfitBold";
  src: url(./asserts/fonts/Outfit-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Outfit";
  src: url(./asserts/fonts/Outfit-Regular.ttf) format("truetype");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  @media screen and (max-width: 1600px) {
    max-width: 1320px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 1140px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 960px;
  }
  @media screen and (max-width: 992px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
  }
  @media screen and (max-width: 576px) {
    width: calc(100% - 42px);
  }
}
.roadmap-container {
  .container {
    z-index: 3;
    background-color: #ffffff;
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    position: relative;
    
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-top: 60px;
    }
    .roadmap-left {
      width: 40%;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }

      h1 {
        font-size: var(--main-font-size);
        margin: 0;
        font-family: "OutfitBold";
        text-align: left;
        @media screen and (max-width: 1024px) {
          font-size: 64px;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 69px;
        }
      }
      
      .img {
        z-index: 0;
        left: 0;
        top: 200px;
        position: absolute;
        width: 60%;

        @media screen and (max-width: 768px) {
          left: 60%;
          top: -40px;
          width: 50%;
          margin: auto;
        }
      }
    }
    .roadmap-right {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .roadmap-component {
        padding: 24px;
        text-align: left;
        position: relative;

        .phase-background {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 0;
        }

        h2 {
          font-size: 40px;
          font-weight: 700;
          margin: 10px 0px;
          font-family: "OutfitBold";

          position: relative;
          z-index: 1;

          @media screen and (max-width: 768px) {
            font-size: 32px;
          }
        }
        p {
          font-size: 16px;
          margin-bottom: 0;
          font-family: "Outfit";

          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
